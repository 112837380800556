@import "src/scss/variables";

.textarea {
  
  textarea {
    font-family: inherit;
    height: 60px;
    padding: 8px 16px;
    resize: vertical;
    max-height: 50vh;
  }
}
