.start-page {
  
  h2 {
    margin-bottom: 24px;
  }
  
  &__info-block {
    width: 500px;
    padding: 24px;
    line-height: 1.5;
    border: 1px solid gray;
  }
}
