@import "src/scss/variables";

.layout {
  display: flex;
  
  &__sidebar {
    width: 220px;
    height: 100vh;
    background: $sidebar-background;
    
    .logo {
      background: $white;
      display: flex;
      flex-direction: column;
      color: $sidebar-background;
      font-weight: bold;
      padding: 10px 40px;
      
      hr {
        margin: 10px 0;
      }
    }
  }
  
  &__main {
    width: calc(100vw - 220px);
  }
  
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 0 20px;
    box-shadow: $shadow;
  }
  
  &__header-user {
    display: flex;
    align-items: center;
    
    &-name {
      font-size: 14px;
      margin-right: 24px;
    }
  }
  
  &__navigation {
    
    &-item {
      padding: 2px 0 16px;
    }
    
    a {
      display: flex;
      align-items: center;
      color: $light-grey;
      font-weight: 700;
      padding: 9px 10px 9px 21px;
      font-size: 14px;
      text-decoration: none;
      transition: all .3s;
      
      &:hover,
      &:focus,
      &.active {
        background: $sidebar-active-item-background;
      }
    }
    
    i {
      margin-right: 10px;
    }
  }
  
  &__page-content {
    height: calc(100vh - 60px);
    overflow: auto;
    padding: 20px;
  }
}
