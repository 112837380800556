$black: #000;
$white: #fff;
$grey: #f4f4f4;
$light-grey: #f1f1f1;
$error: #b10423;

$text-color: #333;

$default-color: $grey;
$default-border-color: #ddd;

$primary-color: #367fa9;
$primary-border-color: #367fa9;

$success-color: #008d4c;
$success-border-color: #398439;

$info-color: #00c0ef;
$info-border-color: #00acd6;

$warning-color: #e08e0b;
$warning-border-color: #d58512;

$danger-color: #dd4b39;
$danger-border-color: #d73925;


$sidebar-background: #593830;
$sidebar-active-item-background: #775853;

$disabled-input-bg: $light-grey;

$shadow: 0 1px 4px rgba($black, .25);

$btn-font-size: 14px;
$btn-border-radius: 2px;
