@import "src/scss/variables";

.confirm-modal {
  width: 300px;
  
  .modal__header {
    border: none;
    background: $white;
    color: $text-color;
  }
  
  .modal__footer {
    background: $white;
    border-top: none;
  }
}
