@import "src/scss/variables";

.form-lang-switch {
  
  &__controls {
    display: flex;
    cursor: pointer;
    margin-bottom: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid $default-border-color;
  }
  
  &__controls-item {
    text-transform: uppercase;
    padding: 4px 16px;
    border: 1px solid transparent;
    
    &:hover {
      opacity: .5;
      border-color: $default-border-color;
      background: $white;
    }
    
    &--active {
      opacity: 1 !important;
      border-color: $default-border-color;
      background: $white;
    }
  }
  
  &__item {
    display: none;
    
    &--active {
      display: block;
    }
  }
}
