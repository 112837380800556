@import "src/scss/variables";

.select {
  position: relative;
  width: 100%;
  
  &__opener {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 32px;
    padding: 8px 8px 8px 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border: 1px solid $default-border-color;
    background: $white;
    user-select: none;
    font-size: 12px;
    border-radius: 2px;
  }
  
  &__items {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
    background-color: $white;
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: $default-border-color;
    max-height: 150px;
    overflow: auto;
    z-index: 5;
    font-size: 12px;
  }
  
  &__item {
    padding: 0 15px;
    line-height: 26px;
    transition: all .3s;
    user-select: none;
    
    &:hover {
      cursor: pointer;
      background: $default-color;
    }
  }
  
  &--show &__items {
    display: block;
  }
  
  &--disabled &__opener {
    background-color: $disabled-input-bg;
  }
}
