.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all .5s;
  
  img {
    width: 60px;
    height: 60px;
    margin-top: -60px;
  }
  
  &.show {
    opacity: 1;
  }
}
