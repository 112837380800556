@import "src/scss/variables";

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: $light-grey;
  
  &__title {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  &__form {
    width: 300px;
    padding: 20px;
    box-shadow: $shadow;
    background: $white;
    
    .button {
      width: 100%;
      margin-top: 16px;
    }
  }
}
