@import "src/scss/variables";

.table-page {
  
  &__toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    button {
      margin-left: 10px;
    }
  }
  
  &__filter-row {
    td:last-child {
      text-align: right;
    }
  }
  
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 12px;
    font-size: 12px;
    
    a {
      font-weight: 700;
      font-size: 14px;
      color: $text-color;
      
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
  
  tbody {
    tr {
      transition: background .3s ease;
      border-radius: 2px;
      box-shadow: $shadow;
    }
    
    td {
      border-width: 1px 0;
      border-style: solid;
      border-color: transparent;
      transition: border .3s ease;
      
      &:first-child {
        border-radius: 2px 0 0 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-width: 1px 0 1px 1px;
      }
      
      &:last-child {
        border-radius: 0 2px 2px 0;
        border-width: 1px 1px 1px 0;
      }
    }
  }
  
  th {
    font-weight: bold;
    text-align: left;
    font-size: 12px;
    text-transform: uppercase;
    
    &.sortable {
      cursor: pointer;
      user-select: none;
      
      div {
        display: flex;
        align-items: center;
      }
    }
    
    &.asc,
    &.desc {
      font-weight: bold;
    }
  }
  
  td {
    padding: 6px 8px;
  }
  
  th {
    padding: 4px 8px;
  }
  
  th,
  td {
    &:first-child {
      padding-left: 10px;
    }
  }
  
  td.actions {
    .button-box {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  
  &__no-results {
    text-align: center;
    padding: 8px;
    font-size: 12px;
  }
}
