@import "variables";

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

body {
  color: $text-color;
  background: $white;
  min-width: 1000px;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-print-color-adjust: exact;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="date"],
select,
textarea {
  width: 100%;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  font-size: 12px;
  line-height: normal;
  border-radius: 2px;
  border: 1px solid $default-border-color;
  color: $text-color;
  background-color: $white;
}
