.role-form-modal {
  max-width: 400px;
  
  &__permission-title {
    margin: 16px 0 8px;
  }
  
  .form-field.checkbox {
    margin-bottom: 0;
  }
}
