@import "src/scss/variables";

.checkbox {
  
  &__box-label {
    display: flex;
    align-items: center;
    height: 32px;
    margin: 0;
  }
  
  input[type="checkbox"] {
    cursor: pointer;
  }
  
  &__title {
    user-select: none;
    padding: 0 8px;
    cursor: pointer;
  }
}
