.order-form-modal {
  
  table {
    margin-top: -10px;
    
    tr {
      background: #fff;
    }
    
    td {
      white-space: normal !important;
      width: 40px;
    }
  }
}
