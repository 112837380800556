@import "variables";

.modal-holder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($black, .5);
  z-index: 10;
  opacity: 0;
  transition: opacity .3s ease, visibility .3s ease;
  overflow: hidden;
  padding: 0 10px;
  
  &.show {
    opacity: 1;
  }
}

.modal {
  position: relative;
  background: $white;
  width: 100%;
  max-width: 700px;
  font-size: 14px;
  box-shadow: 0 1px 4px rgba($black, .23);
  border-radius: 4px;
  
  &--w400 {
    max-width: 400px;
  }
  
  &--w800 {
    max-width: 800px;
  }
  
  &--w1000 {
    max-width: 1000px;
  }
  
  &__header {
    display: flex;
    padding: 16px;
    border-bottom: 1px solid $default-border-color;
    background: $primary-color;
    color: $white;
    border-radius: 4px 4px 0 0;
    font-weight: bold;
    
    h2 {
      display: flex;
      align-items: center;
      font-weight: 400;
      margin: 0;
    }
    
    .button {
      min-width: 120px;
      margin-left: 20px;
    }
  }
  
  &__body {
    display: grid;
    grid-column-gap: 16px;
    padding: 16px 24px;
    background: $default-color;
    max-height: 70vh;
    overflow: auto;
    
    &--2-cols {
      grid-template-columns: 1fr 1fr;
    }
    
    &--3-cols {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  
  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 24px;
    background: $default-color;
    border-radius: 0 0 4px 4px;
    border-top: 1px solid $default-border-color;
    
    .button {
      min-width: 80px;
      margin-right: 8px;
      
      &:last-child {
        margin: 0;
      }
    }
  }
}
