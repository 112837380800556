.room-form-modal {
  
  .modal__body {
    grid-template-columns: 1fr 2fr;
    
    .image-array-uploader__items {
      display: grid;
      grid-column-gap: 16px;
      grid-template-columns: 1fr 1fr;
    }
  }
}
