@import "src/scss/variables";

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  
  &__link {
    width: 30px;
    height: 30px;
    font-size: 14px;
    line-height: 26px;
    font-weight: bold;
    text-align: center;
    border: 1px solid $primary-color;
    border-radius: 2px;
    margin: 0 5px;
    cursor: pointer;
    user-select: none;
    transition: all .3s ease;
    color: $primary-color;
    opacity: 1;
    
    &.active,
    &:hover {
      &:not(.disabled) {
        background: $primary-color;
        color: $white;
      }
    }
    
    &-points {
      opacity: .25;
      cursor: default;
    }
  }
}
