@import "src/scss/variables";

.input {
  
  input {
    height: 32px;
    padding: 0 16px;
    
    &[disabled] {
      background-color: $disabled-input-bg;
    }
  }
}
