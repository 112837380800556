@import "src/scss/variables";

.file-uploader {
  
  &__box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $default-border-color;
    padding: 4px;
  }
  
  &__uploader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 120px;
    margin: 0;
    transition: all .3s;
    
    &:hover {
      opacity: .7;
      cursor: pointer;
    }
  }
  
  &__icon {
    font-size: 30px;
  }
  
  &__preview {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  &__preview-image {
    height: 100px;
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  &__preview-input {
    display: block;
    width: 100%;
    background: transparent;
    border: none;
    border-top: 1px solid $default-border-color;
    margin-top: 4px;
    outline: none;
    padding-top: 4px;
    font-size: 11px;
  }
  
  &__preview-delete {
    position: absolute;
    right: 0;
    top: 0;
    
    &:hover {
      opacity: .7;
      cursor: pointer;
    }
  }
  
  input[type="file"] {
    display: none;
  }
}
