@import "src/scss/variables";

.button {
  display: inline-block;
  height: 32px;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid;
  padding: 0 10px;
  font-size: $btn-font-size;
  border-radius: $btn-border-radius;
  transition: all .3s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  white-space: nowrap;
  
  &:hover:not(:disabled) {
    cursor: pointer;
    opacity: .6;
  }
  
  &-default {
    color: $text-color;
    background: $default-color;
    border-color: $default-border-color;
  }
  
  &-primary {
    color: $white;
    background: $primary-color;
    border-color: $primary-border-color;
  }
  
  &-success {
    color: $white;
    background: $success-color;
    border-color: $success-border-color;
  }
  
  &-info {
    color: $white;
    background: $info-color;
    border-color: $info-border-color;
  }
  
  &-warning {
    color: $white;
    background: $warning-color;
    border-color: $warning-border-color;
  }
  
  &-danger {
    color: $white;
    background: $danger-color;
    border-color: $danger-border-color;
  }
  
  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0 4px;
    
    i {
      font-size: 16px;
    }
  }
  
  &--sm {
    font-size: 13px;
    height: 24px;
  }
  
  &--lg {
    font-size: 16px;
    min-height: 45px;
  }
  
  &[disabled] {
    opacity: .25;
  }
}
