@import "src/scss/variables";

.form-field {
  width: 100%;
  margin-bottom: 10px;
  
  &__label {
    display: block;
    font-size: 12px;
    margin-bottom: 5px;
    user-select: none;
  }
  
  &__error {
    font-size: 12px;
    line-height: 1.5;
    color: $error;
  }
}
